import Image from "next/image";
import CloudinaryImage from "./CloudinaryImage";
import { CldImageProps } from "next-cloudinary";
type Props = CldImageProps & { external?: boolean; quality?: number };
const CustomImage = (props: Props) => {
  const { external, src, ...imageProps } = props;
  if (external) {
    return (
      <Image
        {...imageProps}
        blurDataURL={src}
        src={src === "default" || src === "" ? "/images/userImage.jpg" : src}
      />
    );
  }

  return (
    <CloudinaryImage
      {...imageProps}
      src={src === "default" || src === "" ? "/userImage_xi67vc.jpg" : src}
    />
  );
};

export default CustomImage;
