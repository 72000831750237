"use client";
import { CldImage, CldImageProps } from "next-cloudinary";
import { FC } from "react";
interface Props extends CldImageProps {
  src: string;
}
const CloudinaryImage: FC<Props> = (props: Props) => {
  const { src, ...imageProps } = props;
  return (
    <CldImage
      {...imageProps}
      src={src === "default" || src === "" ? "/userImage_xi67vc.jpg" : src}
    />
  );
};

export default CloudinaryImage;
