"use client";
import CustomImage from "../CustomImage";
import { isSafari } from "react-device-detect";
const LoadingEffect = () => {
  return (
    <div className="absolute left-0 top-0 w-full h-full max-h-full max-w-full z-[99]">
      <div className="sticky flex items-center justify-center -translate-y-1/2 rounded-full left-1/2 top-1/2">
        {isSafari ? (
          <img src="images/loading.svg" alt="the image" sizes="150px" />
        ) : (
          <div className="w-[min(150px,12vw)] aspect-square relative">
            <CustomImage
              src="loading_sxaz6t.svg"
              alt="the image"
              external={false}
              sizes="min(150px, 12vw)"
              fill
              priority
              className="absolute object-contain rounded-full"
              format="svg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoadingEffect;
